<div class="container">
    <div class="column-12">
        <div class="row">
            <div class="btn btn-circle btn-success" *ngIf="!fuelFailure" (click)="toggleFuelFailure()">
                <img src="/assets/zapfsaeule.svg"/>
            </div>
            <div class="btn btn-circle btn-danger" *ngIf="fuelFailure" (click)="toggleFuelFailure()">
                <img src="/assets/zapfsaeule.svg"/>
            </div>
        </div>
        <div class="row">
            <div class="btn btn-circle btn-success" *ngIf="!windowFailure" (click)="toggleWindowFailure()">
                <img src="/assets/window-open.svg"/>
            </div>
            <div class="btn btn-circle btn-danger" *ngIf="windowFailure" (click)="toggleWindowFailure()">
                <img src="/assets/window-open.svg"/>
            </div>
        </div>
        <div class="row">
            <div class="btn btn-circle btn-success" *ngIf="!tireFailure" (click)="toggleTireFailure()">
                <img src="/assets/tire.svg"/>
            </div>
            <div class="btn btn-circle btn-danger" *ngIf="tireFailure" (click)="toggleTireFailure()">
                <img src="/assets/tire.svg"/>
            </div>
        </div>
        <div class="row">
            <div class="btn btn-circle btn-success" *ngIf="!lightFailure" (click)="toggleLightFailure()">
                <img src="/assets/light.svg"/>
            </div>
            <div class="btn btn-circle btn-danger" *ngIf="lightFailure" (click)="toggleLightFailure()">
                <img src="/assets/light.svg"/>
            </div>
        </div>
        <div class="row">
            <div class="btn btn-circle btn-success" *ngIf="!lambdaFailure" (click)="toggleLambdaFailure()">
                <img src="/assets/lambda.svg"/>
            </div>
            <div class="btn btn-circle btn-danger" *ngIf="lambdaFailure" (click)="toggleLambdaFailure()">
                <img src="/assets/lambda.svg"/>
            </div>
        </div>
        <div class="row">
            <div class="btn btn-circle btn-success" *ngIf="!catalystFailure" (click)="toggleCatalystFailure()">
                <img src="/assets/catalyst.svg"/>
            </div>
            <div class="btn btn-circle btn-danger" *ngIf="catalystFailure" (click)="toggleCatalystFailure()">
                <img src="/assets/catalyst.svg"/>
            </div>
        </div>
        <div class="row">
            <div class="btn btn-circle btn-success" *ngIf="!iceFailure" (click)="toggleIceFailure()">
                <img src="/assets/snowflake.svg"/>
            </div>
            <div class="btn btn-circle btn-danger" *ngIf="iceFailure" (click)="toggleIceFailure()">
                <img src="/assets/snowflake.svg"/>
            </div>
        </div>
    </div>