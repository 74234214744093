import { Message } from './message';

export class Diagnose {
    date: string;
    readonly diagnoseItems: DiagnoseItem[] = [];

    constructor(
    ) {
        this.date = new Date().toLocaleString();
    }

    addDiagnoseItem(diagnoseItem: DiagnoseItem): void {
        this.diagnoseItems.push(diagnoseItem);
    }

    removeDiagnoseItem(diagnoseItem: DiagnoseItem): void {
        const i = this.diagnoseItems.indexOf(diagnoseItem);
        if (i !== -1) {
            this.diagnoseItems.splice(i, 1);
        }
    }

    lastCheck(): string {
        return this.date;
    }

    getErrorMessages(): DiagnoseItem[] {
        return this.diagnoseItems.filter(diagnoseItem => diagnoseItem.errorType === ErrorType.error);
    }

    getWarningMessages(): DiagnoseItem[] {
        return this.diagnoseItems.filter(diagnoseItem => diagnoseItem.errorType === ErrorType.warning);
    }

    hasErrors(): boolean {
        return this.getErrorMessages().length > 0;
    }
}

export class DiagnoseItem {
    private static ID = 0;

    readonly date: string;
    readonly id: number = DiagnoseItem.ID++;
    public _showDetails: boolean;

    constructor(
        readonly title: string,
        readonly text: string,
        readonly errorCode: string,
        readonly errorType: ErrorType,
    ) {
        this.date = new Date().toLocaleString();
    }

    createMessage(): Message {
        return new Message(this.title, 'diagnosis', 'diagnosis');
    }

    toggleShowDetails(): void {
        this._showDetails = !this._showDetails;
    }

    showDetails(): boolean {
        return this._showDetails;
    }
}

export enum ErrorType {
    warning = 'Warnung', error = 'Fehler'
}
