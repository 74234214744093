import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select/select';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Car } from 'src/app/models/car';
import { CarWorkshop } from 'src/app/models/car-workshop';
import { CarWorkshopService } from 'src/app/services/car-workshop.service';
import { CarService } from 'src/app/services/car.service';

@Component({
  selector: 'app-car-workshop',
  templateUrl: './car-workshop.component.html',
  styleUrls: ['./car-workshop.component.css']
})
export class CarWorkshopComponent implements OnInit {

  carId: number;
  car: Car;
  alertText = '';
  preSelectIndex: number;

  workshops: CarWorkshop[] = [];
  selectedWorkshop: CarWorkshop;

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public carService: CarService,
    public workshopService: CarWorkshopService
  ) {
    route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });
    this.car = carService.getCarById(this.carId);
    this.workshops = workshopService.getMockWorkshops();
  }

  ngOnInit(): void {
    this.selectedWorkshop = this.workshops[this.carService.getCarById(this.carId).profile.favoriteWorkshopId];
  }

  setAlertStatus(status: boolean): void {
    if (status) {
      this.alertText = 'Der Diagnose-Bericht wurde an die Werkstatt gesendet.\nEin Mitarbeiter wird sich zeitnah mit Ihnen in Verbindung setzen.';
    } else {
      this.alertText = '';
    }
  }

  createDisplayNames(): string[] {
    return this.workshops.map(workshop => workshop.displayWithDistance());
  }

  handleSearchIndex(index: number): void {
    this.selectedWorkshop = this.workshops[index];
  }

}
