<div class="d-flex flex-column justify-content-between" style="height:100%">
  <div>
    <app-titlebar name="Verriegelung" back="/cars/{{carId}}"></app-titlebar>
  </div>
  <div class="container">
    <table class="table table-sm w-100 h-100">
      <tbody>
        <tr>
          <td class="w-25">
            <app-window-preview (brokenWindowEvent)="windowAlert($event)" [window]="car.getWindow(0, side.Left)">
            </app-window-preview>
            <app-door-preview [door]="car.getDoor(0, side.Left)"></app-door-preview>
          </td>
          <td class="w-50" [attr.rowspan]="car.getNumberOfRows()">
            <img src="/assets/car.svg" style="width:100%" />

            <ngb-alert role="alert" type="danger" *ngIf="windowAlertText.length > 0" (closed)="closeAlert()"
              class="show align-self-center padd alert-danger fade alert-dismissible" ng-reflect-type="danger"><a class="mr-5 alert-text" [routerLink]="'/cars/' + carId + '/diagnosis'">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="mr-1 bi bi-arrow-right-square-fill" stroke="#FF0000" fill="#F6CECE"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 8.5a.5.5 0 0 1 0-1h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5z" />
                </svg>
                {{windowAlertText}}
              </a>
            </ngb-alert>

          </td>
          <td class="w-25">
            <app-window-preview (brokenWindowEvent)="windowAlert($event)" [window]="car.getWindow(0, side.Right)">
            </app-window-preview>
            <app-door-preview [door]="car.getDoor(0, side.Right)"></app-door-preview>
          </td>
        </tr>
        <tr *ngFor="let row of rows.slice(1)">
          <td>
            <app-window-preview (brokenWindowEvent)="windowAlert($event)" [window]="car.getWindow(row, side.Left)">
            </app-window-preview>
            <app-door-preview [door]="car.getDoor(row, side.Left)"></app-door-preview>
          </td>
          <td>
            <app-window-preview (brokenWindowEvent)="windowAlert($event)" [window]="car.getWindow(row, side.Right)">
            </app-window-preview>
            <app-door-preview [door]="car.getDoor(row, side.Right)"></app-door-preview>
          </td>
        </tr>
        <tr>
          <td class="w-25"></td>
          <td class="w-50 align-top">
            <app-door-preview [door]="car.getTrunk()"></app-door-preview>
          </td>
          <td class="w-25"></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="py-2 align-self-center">
    <app-action icon="lock-closed" name="Türen entriegeln" (clicked)="unlockAllDoors()"
      *ngIf="car.isShutdown(); else closedblock"></app-action>
  </div>
</div>

<ng-template #closedblock>
  <app-action icon="lock-open" name="Alles verriegeln" (clicked)="shutdown()"></app-action>
</ng-template>