<div class="container">
  <div class="row mb-3">
    <div class="col-6 title">{{name}}</div>
    <div class="col-4">
      <img src="/assets/{{icon}}.svg" height="40px" />
    </div>
    <div class="col-2">
      <a *ngIf="!showDetails" class="btn arrow chevron down" (click)="toggleShowDetails()"></a>
      <a *ngIf="showDetails" class="btn arrow chevron up" (click)="toggleShowDetails()"></a>
    </div>
  </div>
  <div *ngIf="showDetails">
    <div class="row" *ngFor="let entry of item.getSettingsMap() | keyvalue" style="align-items: center">
      <div class="col-6 text">{{entry.key}}</div>
      <div class="col-6">
        <input *ngIf="entry.value.getInputType() === inputType.Text" [value]="entry.value.display()[0]"
          (blur)="entry.value.set($event.target.value)" class="w-100" />
        <div *ngIf="entry.value.getInputType() === inputType.Dropdown" ngbDropdown>
          <button class="btn btn-sm" id="dropDownMenu{{id}}" ngbDropdownToggle>{{entry.value.display()[0]}}</button>
          <div ngbDropdownMenu aria-labelledby="dropDownMenu{{id}}" style="background-color: var(--dropdown-background)">
            <div ngbDropdownItem *ngFor="let item2 of entry.value.display()" [value]="item2"
              (click)="entry.value.set($event.target.value)" class="item">{{item2}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>