import { Component, Input } from '@angular/core';
import { InputType, SettingsConfigurable } from 'src/app/models/information-settings-items/interfaces';

@Component({
  selector: 'app-settings-item',
  templateUrl: './settings-item.component.html',
  styleUrls: ['./settings-item.component.css']
})
export class SettingsItemComponent {

  @Input() name: string;
  @Input() icon: string;
  @Input() item: SettingsConfigurable;
  @Input() id: string;
  inputType = InputType;

  constructor() { }

  toggleShowDetails(): void {
    this.item.toggleShowSettingsDetails();
  }

  get showDetails(): boolean {
    return this.item.showSettingsDetails();
  }
}
