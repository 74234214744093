<div class="message-box" style="border: 1px solid var(--text);">
  <div class="message-header p-2">
    INFOBOX
  </div>
  <hr style="border-top: 1px solid var(--text);">

  <div class="container">
    <div class="row p-0">
      <div class="mt-2 mb-2 col-12 p-0" *ngIf="noMessages()" style="color: lime">
        Es gibt keine Informationen
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row p-0">
      <div class="col-3 p-0 d-flex flex-column justify-content-center"
        style="color:red; border-right: 1px solid var(--text);" *ngIf="errorMessages.length > 0">
        Fehler
      </div>
      <div class="col-9 p-0">
        <div *ngFor="let message of errorMessages; let i = index">
          <div class="message-body m-2" style="text-align: left;">
            <a [routerLink]="'/cars/'+ carId +'/' + message.destination">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right-square-fill" fill="var(--text)"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 8.5a.5.5 0 0 1 0-1h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5z" />
              </svg>
              <span class="ml-2">{{message.text}}</span>
            </a>
          </div>
          <hr *ngIf="i != (errorMessages.length - 1)" style="border-top: 1px solid var(--text);">
        </div>
      </div>
    </div>
  </div>

  <hr *ngIf="errorMessages.length > 0 && warningMessages.length > 0" style="border-top: 1px solid var(--text);">

  <div class="container">
    <div class="row p-0">
      <div class="col-3 p-0 d-flex flex-column justify-content-center"
        style="color:orange; border-right: 1px solid var(--text);" *ngIf="warningMessages.length > 0">
        Warnung
      </div>
      <div class="col-9 p-0">
        <div *ngFor="let message of warningMessages; let i = index">
          <div class="message-body m-2" style="text-align: left;">
            <a [routerLink]="'/cars/'+ carId +'/' + message.destination">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right-square-fill" fill="var(--text)"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 8.5a.5.5 0 0 1 0-1h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5z" />
              </svg>
              <span class="ml-2">{{message.text}}</span>
            </a>
          </div>
          <hr *ngIf="i != (warningMessages.length - 1)" style="border-top: 1px solid var(--text);">
        </div>
      </div>
    </div>
  </div>

  <hr
    *ngIf="(errorMessages.length > 0 && warningMessages.length > 0) || (warningMessages.length > 0 && infoMessages.length > 0) || (errorMessages.length > 0 && infoMessages.length > 0)"
    style="border-top: 1px solid var(--text);">

  <div class="container">
    <div class="row p-0">
      <div class="col-3 p-0 d-flex flex-column justify-content-center"
        style="color:lightblue; border-right: 1px solid var(--text);" *ngIf="infoMessages.length > 0">
        Info
      </div>
      <div class="col-9 p-0">
        <div *ngFor="let message of infoMessages; let i = index">
          <div class="message-body m-2" style="text-align: left;">
            <a [routerLink]="'/cars/'+ carId +'/' + message.destination">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right-square-fill" fill="var(--text)"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 8.5a.5.5 0 0 1 0-1h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5z" />
              </svg>
              <span class="ml-2">{{message.text}}</span>
            </a>
          </div>
          <hr *ngIf="i != (infoMessages.length - 1)" style="border-top: 1px solid var(--text);">
        </div>
      </div>
    </div>
  </div>
</div>
