import { Message } from './message';
import { DrivingRange, FuelLevel, FuelSystem } from './information-settings-items/fuel-system';
import { InformationDashboardDisplayable, SettingsConfigurable } from './information-settings-items/interfaces';
import { Location, Side } from './location';
import { Percent } from './percent';
import { ClimateControl } from './information-settings-items/climate-control';
import { TirePressure } from './information-settings-items/tire-pressure';
import { LightSystem } from './information-settings-items/lights';
import { Diagnose, DiagnoseItem } from './diagnose';

export class Car {
    private static ID = 0;

    readonly id = Car.ID++;
    private windows: CarWindow[] = [];
    private doors: CarDoor[] = [];
    readonly diagnose = new Diagnose();
    readonly fuelSystem = new FuelSystem(new FuelLevel(50), new Percent(100), new DrivingRange(700000), new DrivingRange(70000));
    readonly climateControl = new ClimateControl();
    readonly tirePressure = new TirePressure();
    readonly lightSystem = new LightSystem();
    readonly informationDashboardItems: InformationDashboardDisplayable[] = [];
    readonly settingsItems: SettingsConfigurable[] = [];
    public profile = new Profile();
    warningMessages: Message[] = [];
    errorMessages: Message[] = [];
    infoMessages: Message[] = [];

    constructor(
        readonly name: string,
    ) {
        this.informationDashboardItems.push(this.fuelSystem);
        this.settingsItems.push(this.fuelSystem);
        this.settingsItems.push(this.climateControl);
        this.settingsItems.push(this.tirePressure);
    }

    getInformationBadgeCount(): number {
        let count = 0;

        this.informationDashboardItems.forEach(i => count += i.getBadgeCount());

        return count;
    }

    getNumberOfRows(): number {
        return 2;
    }

    addDoor(door: CarDoor): void {
        this.doors.push(door);
    }

    getDoor(rowid: number, side: Side): CarDoor {
        return this.doors.find(door => door.hasLocation(rowid, side));
    }

    getTrunk(): CarDoor {
        return this.doors[this.doors.length - 1];
    }

    lockAllDoors(): void {
        this.doors.forEach(door => door.lock());
    }

    unlockAllDoors(): void {
        this.doors.forEach(door => door.unlock());
    }

    areDoorsLocked(): boolean {
        return this.doors.every(door => door.isLocked());
    }

    getRandomWindow(): CarWindow {
        return this.windows[Math.floor(Math.random() * this.windows.length)];
    }

    addWindow(window: CarWindow): void {
        this.windows.push(window);
    }

    getWindow(rowid: number, side: Side): CarWindow {
        return this.windows.find(window => window.hasLocation(rowid, side));
    }

    closeAllWindows(): void {
        this.windows.forEach(window => {
            if (window.isWorking()) {
                window.close();
            }
        });
    }

    areWindowsClosed(): boolean {
        return this.windows.every(window => window.isClosed());
    }

    getBrokenWindows(): CarWindow[] {
        const brokenWindows: CarWindow[] = [];
        this.windows.forEach(w => {
            if (w.isBroken()) {
                brokenWindows.push(w);
            }
        });
        return brokenWindows;
    }

    shutdown(): void {
        this.lockAllDoors();
        this.closeAllWindows();
    }

    isShutdown(): boolean {
        return this.areDoorsLocked() && this.areWindowsClosed();
    }

    addWarningMessage(message: Message): void {
        this.warningMessages.push(message);
    }

    addErrorMessage(message: Message): void {
        this.errorMessages.push(message);
    }

    addInfoMessage(message: Message): void {
        this.infoMessages.push(message);
    }

    removeInfoMessage(message: Message): void {
        const i = this.infoMessages.indexOf(message);
        if (i !== -1) {
            this.infoMessages.splice(i, 1);
        }
    }

    hasMessages(): boolean {
        return this.warningMessages.length > 0 || this.errorMessages.length > 0 || this.infoMessages.length > 0;
    }

    updateMessages(): void {
        this.warningMessages = [];
        this.errorMessages = [];

        if (this.diagnose.diagnoseItems.length > 0) {
            this.diagnose.getErrorMessages().forEach(t => this.addErrorMessage(t.createMessage()));
            this.diagnose.getWarningMessages().forEach(t => this.addWarningMessage(t.createMessage()));
        }

        if (this.fuelSystem.isLowFuelLevel) {
            if (this.fuelSystem.isLowFuelLevel) {
                this.addWarningMessage(new Message('Bitte tanken', 'zapfsaeule', 'gas'));
            }
        }
    }

    addDiagnoseItem(diagnoseItem: DiagnoseItem): void {
        this.diagnose.addDiagnoseItem(diagnoseItem);
    }

    removeDiagnoseItem(diagnoseItem: DiagnoseItem): void {
        this.diagnose.removeDiagnoseItem(diagnoseItem);
    }
}

export class CarDoor {
    constructor(
        readonly location: Location,
        private _isLocked: boolean
    ) { }

    isLocked(): boolean {
        return this._isLocked;
    }

    lock(): void {
        this._isLocked = true;
    }

    unlock(): void {
        this._isLocked = false;
    }

    hasLocation(row: number, side: Side): boolean {
        return this.location.hasRow(row) && this.location.hasSide(side);
    }
}

export class CarWindow {
    // VSS position:100 == fenster zu
    constructor(
        readonly location: Location,
        private _position: Percent,
        private _broken = false
    ) { }

    get position(): number {
        return 100 - this._position.value;
    }

    isClosed(): boolean {
        return this._position.isMaximum();
    }

    close(): void {
        if (this.isWorking()) {
            this._position.setMaximum();
        }
    }

    open(): void {
        if (this.isWorking()) {
            this._position.setMinimum();
        }
    }

    hasLocation(row: number, side: Side): boolean {
        return this.location.hasRow(row) && this.location.hasSide(side);
    }

    isWorking(): boolean {
        return !this._broken;
    }

    isBroken(): boolean {
        return this._broken;
    }

    break(): void {
        this._broken = true;
    }

    repair(): void {
        this._broken = false;
    }

    display(): string {
        return 'Fenster ' + this.location.display();
    }
}

export class Profile {

    public name: string;
    public phone: string;
    public favoriteWorkshopId: number;

    constructor() { }
}
