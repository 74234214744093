import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformationDashboardDisplayable } from 'src/app/models/information-settings-items/interfaces';
import { CarService } from '../../services/car.service';

@Component({
  templateUrl: './information-dashboard.component.html',
  styleUrls: ['./information-dashboard.component.css']
})
export class InformationDashboardComponent {

  carId: number;
  items: InformationDashboardDisplayable[];

  constructor(
    private route: ActivatedRoute,
    private service: CarService
  ) {
    route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });

    this.items = this.service.getCarById(this.carId).informationDashboardItems;
  }
}
