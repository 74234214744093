import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.css']
})
export class SearchBoxComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() displayNames: string[];
  @Input() preSelectIndex: number;
  @Output() selectedIndex = new EventEmitter<number>();

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  public itemControl: FormControl = new FormControl();
  public filterControl: FormControl = new FormControl();
  public filteredItems: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  constructor() { }

  ngOnInit(): void {
    this.itemControl.setValue(this.displayNames[this.preSelectIndex === undefined ? 0 : this.preSelectIndex]);
    this.filteredItems.next(this.displayNames.slice());
    this.filterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItems();
      });
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterItems(): void {
    const search = this.filterControl.value;
    this.filteredItems.next(
      this.displayNames.filter(item => item.toLowerCase().indexOf(search) > -1)
    );
  }

  protected setInitialValue(): void {
    this.filteredItems
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: string, b: string) => a && b && a === b;
      });
  }

  passIndexToParent(element: string): void {
    this.selectedIndex.emit(this.displayNames.findIndex(name => name === element));
  }

}
