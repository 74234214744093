export interface InformationDashboardDisplayable {
    getName(): string;
    getIcon(): string;
    getDisplayMap(): Map<string, DisplayInfos>;
    getBadgeCount(): number;
    toggleShowInformationDetails(): void;
    showInformationDetails(): boolean;
}

export class DisplayInfos {
    constructor(
        public displayString: string[],
        public displayColor: string
    ) { }
}

export interface SettingsConfigurable {
    getName(): string;
    getIcon(): string;
    getSettingsMap(): Map<string, Configurable>;
    toggleShowSettingsDetails(): void;
    showSettingsDetails(): boolean;
}

export interface Configurable {
    getInputType(): InputType;
    display(): string[];
    set(value: string): void;
}

export enum InputType {
    Text, Dropdown
}
