import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {

  @Input() icon: string;
  @Input() name: string;
  @Input() destination: string;
  @Input() badgeStr: string;

  constructor() { }

}
