export class Percent {
    constructor(public value: number) { }

    setMaximum(): void {
        this.value = 100;
    }

    setMinimum(): void {
        this.value = 0;
    }

    isMaximum(): boolean {
        return this.value === 100;
    }

    isMinimum(): boolean {
        return this.value === 0;
    }

    displayValue(): string {
        return this.value + '%';
    }
}
