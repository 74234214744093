import { Component, Input, OnInit } from '@angular/core';
import { CarService } from 'src/app/services/car.service';

@Component({
  selector: 'app-target-indoor-configuration',
  templateUrl: './target-indoor-configuration.component.html',
  styleUrls: ['./target-indoor-configuration.component.css']
})
export class TargetIndoorConfigurationComponent implements OnInit {

  @Input() carId: number;

  currentIndoorTemp: string;
  currentOutdoorTemp: string;

  get temperature(): string { return this.service.getCarById(this.carId).climateControl.targetIndoorTemp.display()[0]; }

  constructor(
    private service: CarService
  ) {
  }

  ngOnInit(): void {
    this.currentIndoorTemp = this.service.getCarById(this.carId).climateControl.currentIndoorTemp.display()[0];
    this.currentOutdoorTemp = this.service.getCarById(this.carId).climateControl.currentOutdoorTemp.display()[0];
  }

  handleClickPlus(): void {
    this.service.getCarById(this.carId).climateControl.addToTargetTemperature(1);
  }

  handleClickMinus(): void {
    this.service.getCarById(this.carId).climateControl.addToTargetTemperature(-1);
  }

}
