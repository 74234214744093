import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Car, CarWindow } from 'src/app/models/car';
import { Side } from 'src/app/models/location';
import { CarService } from 'src/app/services/car.service';

@Component({
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent {

  carId: number;
  car: Car;
  side = Side;
  rows: Array<any>;
  windowAlertText = '';

  constructor(
    private route: ActivatedRoute,
    private service: CarService
  ) {
    route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });

    this.car = this.service.getCarById(this.carId);
    this.rows = [];
    for (let i = 0; i < this.car.getNumberOfRows(); i++) {
      this.rows.push(i);
    }
  }

  getIconName(): string {
    return 'car';
  }

  getDisplayName(): string {
    return 'Verriegelung';
  }

  getDestination(): string {
    return '/cars/{{carId}}/overview';
  }

  getNotificationCount(): number {
    return this.car.isShutdown ? 0 : 1;
  }

  shutdown(): void {
    this.car.shutdown();

    if (!this.car.isShutdown()) {
      this.windowAlert(this.car.getBrokenWindows());
    }
  }

  unlockAllDoors(): void {
    this.car.unlockAllDoors();
  }

  windowAlert(windows: CarWindow[]): void {
    let str = '';
    windows.forEach(w => {
      str +=  w.display() + ' defekt\n';
    });

    this.windowAlertText = str;
  }

  closeAlert(): void {
    this.windowAlertText = '';
  }
}
