<div class="d-flex rounded searchbox m-auto">
  <mat-form-field class="w-100">
    <mat-select [formControl]="itemControl" #singleSelect (selectionChange)="passIndexToParent($event.value)" ngDefaultControl>
      <mat-option>
        <ngx-mat-select-search [formControl]="filterControl" ngDefaultControl></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let item of filteredItems | async;" [value]="item">
        {{item}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
