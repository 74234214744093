import { Configurable, InputType, SettingsConfigurable } from './interfaces';

export class ClimateControl implements SettingsConfigurable {
    readonly targetIndoorTemp = new Temperature(22, this.tempUnit);
    readonly currentIndoorTemp = new Temperature(16, this.tempUnit);
    readonly currentOutdoorTemp = new Temperature(12, this.tempUnit);
    private _showSettingsDetails = true;

    constructor(
        readonly tempUnit = new TempUnit(),
        public active: boolean = false,
        public timeConfigurations: TimeConfiguration[] = [new TimeConfiguration(1), new TimeConfiguration(2)]
    ) { }

    addToTargetTemperature(value: number): void {
        this.targetIndoorTemp.set(this.targetIndoorTemp.getValue() + value + '');
    }

    getName(): string {
        return 'Temperatur';
    }

    getIcon(): string {
        return 'thermometer';
    }

    getSettingsMap(): Map<string, Configurable> {
        const map = new Map<string, Configurable>();

        map.set('Einheit', this.tempUnit);

        return map;
    }

    toggleShowSettingsDetails(): void {
        this._showSettingsDetails = !this._showSettingsDetails;
    }

    showSettingsDetails(): boolean {
        return this._showSettingsDetails;
    }

    getTimeConfiguration(id: number): TimeConfiguration {
        return this.timeConfigurations.find(timeConfiguration => timeConfiguration.id === id);
    }
}

export enum WeekDay {
    Monday = 'Montag',
    Tuesday = 'Dienstag',
    Wednesday = 'Mittwoch',
    Thursday = 'Donnerstag',
    Friday = 'Freitag',
    Saturday = 'Samstag',
    Sunday = 'Sonntag'
}

export class WeekDayConfiguration {
    constructor(
        readonly weekDay: WeekDay,
        public active: boolean
    ) { }

    get day(): WeekDay {
        return this.weekDay;
    }
}

export class TimeConfiguration {
    id: number;
    readonly configuration: WeekDayConfiguration[] = [
        new WeekDayConfiguration(WeekDay.Monday, true),
        new WeekDayConfiguration(WeekDay.Tuesday, true),
        new WeekDayConfiguration(WeekDay.Wednesday, true),
        new WeekDayConfiguration(WeekDay.Thursday, true),
        new WeekDayConfiguration(WeekDay.Friday, true),
        new WeekDayConfiguration(WeekDay.Saturday, false),
        new WeekDayConfiguration(WeekDay.Sunday, false)
    ];
    public startTime = '08:00';
    public autoConfig = false;

    constructor(id: number) {
        this.id = id;
    }
}

enum TempUnitType {
    F = '°F', C = '°C'
}

export class TempUnit implements Configurable {
    private temperatures: Temperature[] = [];

    constructor(
        public unit: TempUnitType = TempUnitType.C,
    ) { }

    addTemperature(temp: Temperature): void {
        this.temperatures.push(temp);
    }

    getInputType(): InputType {
        return InputType.Dropdown;
    }

    display(): string[] {
        const ret: string[] = [this.unit.toString()];

        if (this.unit === TempUnitType.C) {
            ret.push(TempUnitType.F);
        } else {
            ret.push(TempUnitType.C);
        }

        return ret;
    }

    set(value: string): void {
        value = value.replace('°', '');
        value = value.replace(' ', '');

        if (value === 'C' && this.unit === TempUnitType.F) {
            this.unit = TempUnitType.C;
            this.convertFtoC();
        }

        if (value === 'F' && this.unit === TempUnitType.C) {
            this.unit = TempUnitType.F;
            this.convertCtoF();
        }
    }

    private convertCtoF(): void {
        this.temperatures.forEach(t => {
            t.value = Math.round(t.value * 9 / 5 + 32);
        });
    }

    private convertFtoC(): void {
        this.temperatures.forEach(t => {
            t.value = Math.round((t.value - 32) * 5 / 9);
        });
    }
}

export class Temperature implements Configurable {
    constructor(
        public value: number,
        private tempUnit: TempUnit
    ) {
        tempUnit.addTemperature(this);
    }

    getInputType(): InputType {
        return InputType.Dropdown;
    }

    display(): string[] {
        return [this.value + '' + this.tempUnit.unit];
    }

    set(value: string): void {
        value = value.replace('°', '');
        value = value.replace(' ', '');
        value = value.replace('F', '');
        value = value.replace('C', '');

        this.value = +value;
    }

    getValue(): number {
        return this.value;
    }
}
