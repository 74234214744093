import { Component, Input, OnInit } from '@angular/core';
import { InformationDashboardDisplayable } from 'src/app/models/information-settings-items/interfaces';

@Component({
  selector: 'app-information-dashboard-item',
  templateUrl: './information-dashboard-item.component.html',
  styleUrls: ['./information-dashboard-item.component.css']
})
export class InformationDashboardItemComponent implements OnInit {

  @Input() name: string;
  @Input() icon: string;
  @Input() item: InformationDashboardDisplayable;

  constructor() { }

  ngOnInit(): void {
    // this.showDetails = this.item.getBadgeCount() > 0;
  }

  getBadgeCount(): number {
    return this.item.getBadgeCount();
  }

  toggleShowDetails(): void {
    this.item.toggleShowInformationDetails();
  }

  get showDetails(): boolean {
    return this.item.showInformationDetails();
  }
}
