<div class="d-flex flex-column h-100">
  <app-titlebar name="Werkstatt" back="/cars/{{carId}}/diagnosis"></app-titlebar>
  <div class="d-flex flex-column flex-grow-1 m-3 overflow-auto scrollbar">
    <!-- Infobox -->
    <div class="infotext">
      Senden Sie den Fehlerbericht direkt an eine unserer Partnerwerkstätten und ein Mitarbeiter setzt sich zeitnah mit
      Ihnen in Verbindung.
    </div>
    <!-- Person -->
    <div class="d-flex flex-column">
      <div class="headline">Persönliche Daten</div>
      <div class="d-flex flex-row row">
        <span class="label">Name</span>
        <input type="string" [(ngModel)]="carService.getCarById(this.carId).profile.name">
      </div>
      <div class="d-flex flex-row row">
        <span class="label">Telefonnummer</span>
        <input type="string" [(ngModel)]="carService.getCarById(this.carId).profile.phone">
      </div>
    </div>
    <hr style="border-top: 1px solid var(--text); width: 100%;">
    <!-- Werkstatt -->
    <div class="d-flex flex-column">
      <div class="headline">Werkstatt</div>
      <div class="d-flex flex-row row">
        <!-- Werkstatt Suche -->
        <div class="align-self-center label">
          <span>Suche</span>
        </div>
        <app-search-box [preSelectIndex]="carService.getCarById(this.carId).profile.favoriteWorkshopId"
          [displayNames]="createDisplayNames()" (selectedIndex)="handleSearchIndex($event)">
        </app-search-box>
      </div>
      <!-- Ausgewählte Werkstatt -->
      <div *ngIf="selectedWorkshop !== undefined" class="d-flex flex-column pt-4">
        <div class="d-flex flex-row row">
          <span class="label">Name:</span>
          <span>{{selectedWorkshop.name}}</span>
        </div>
        <div class="d-flex flex-row row">
          <span class="label">Straße:</span>
          <span>{{selectedWorkshop.street}} {{selectedWorkshop.streetAppendix}}</span>
        </div>
        <div class="d-flex flex-row row">
          <span class="label">Stadt:</span>
          <span>{{selectedWorkshop.plz}} {{selectedWorkshop.city}}</span>
        </div>
        <div class="d-flex flex-row row">
          <span class="label">Entfernung</span>
          <span>{{selectedWorkshop.getDistanceDisplay()}}</span>
        </div>
      </div>
    </div>
    <hr style="border-top: 1px solid var(--text); width: 100%;">
    <!-- Bericht -->
    <div class="d-flex flex-column">
      <div class="headline">Bericht</div>
      <div class="d-flex flex-row">
        <div class="d-flex flex-column pt-4">
          <div class="d-flex flex-row row">
            <span class="label">Datum:</span>
            <span>{{car.diagnose.date}}</span>
          </div>
          <div class="d-flex flex-row row">
            <span class="label">Fehler:</span>
            <span>{{car.diagnose.getErrorMessages().length}}</span>
          </div>
          <div class="d-flex flex-row row">
            <span class="label">Warnungen:</span>
            <span>{{car.diagnose.getWarningMessages().length}}</span>
          </div>
        </div>
        <div class="d-flex flex-column ml-5">
          <app-action class="align-self-center" icon="send" name="Fehlerbereicht senden" (click)="setAlertStatus(true)">
          </app-action>
        </div>
      </div>
    </div>
  </div>
  <ngb-alert type="info" *ngIf="alertText.length > 0" (closed)="setAlertStatus(false)">
    {{alertText}}</ngb-alert>
</div>
