import { Theme } from './symbols';

export const greenTheme: Theme = {
  name: 'green',
  properties: {
    '--background': '#316B6F',
    '--on-background': '#fff',
    '--title': '#303b53ff',
    '--button': '#467a7E',
    '--button-hover': '#303b53ff',
    '--text': '#99B6B8',
    '--title-hover': '#fff',
    '--dropdown-text': '#000',
    '--dropdown-background': '#99B6B8',
    '--border': '#FFF'
  }
};
