import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Car } from 'src/app/models/car';
import { GasStation } from 'src/app/models/gas-station';
import { FuelSystem } from 'src/app/models/information-settings-items/fuel-system';
import { CarService } from 'src/app/services/car.service';
import { GasStationService } from 'src/app/services/gas-station.service';

@Component({
  selector: 'app-gas',
  templateUrl: './gas.component.html',
  styleUrls: ['./gas.component.css']
})
export class GasComponent implements OnInit {

  carId: number;
  selectedCar: Car;

  fuelSystem: FuelSystem;

  gasStations: GasStation[] = [];
  selectedGasStation: GasStation;
  gasStationDisplayNames: string[];

  constructor(
    private route: ActivatedRoute,
    private carService: CarService,
    private gasStationService: GasStationService
  ) {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });
    this.selectedCar = this.carService.getCarById(this.carId);
    this.fuelSystem = this.selectedCar.fuelSystem;
    this.gasStations = this.gasStationService.getMockGasStations();
    this.gasStationDisplayNames = this.gasStations.map(gasStation => gasStation.displayWithDistance());
  }

  ngOnInit(): void {
    this.selectedGasStation = this.gasStations[0];
  }

  handleSearchIndex(index: number): void {
    this.selectedGasStation = this.gasStations[index];
  }

  calculatePrice(): string {
    return ((this.selectedCar.fuelSystem.maxLevelLiters._levelInLiters - this.selectedCar.fuelSystem.levelLiters._levelInLiters) * this.selectedGasStation.p).toFixed(2) + ' €';
  }
}
