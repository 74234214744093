import { Theme } from './symbols';

export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    '--background': '#000',
    '--on-background': '#fff',
    '--title': '#666',
    '--button': '#222',
    '--button-hover': '#666',
    '--text': '#FFF',
    '--title-hover': '#222',
    '--dropdown-text': '#fff',
    '--dropdown-background': '#999',
    '--border': '#FFF'
  }
};
