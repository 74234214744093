<div class="d-flex flex-column rounded p-2 main">
  <div class="d-flex justify-content-center">
    {{label}}
  </div>
  <div class="d-flex flex-row justify-content-center mt-2">
    <div>{{labelOff}}</div>
    <div class="d-flex justify-content-center custom-control custom-switch"
      style="margin-left: 8px;">
      <input type="checkbox" class="custom-control-input" id="switch_{{id}}" [(ngModel)]="statusInput"
        (ngModelChange)="passStatusToParent()" />
      <label class="hover custom-control-label" for="switch_{{id}}"></label>
    </div>
    <div>{{labelOn}}</div>
  </div>
</div>
