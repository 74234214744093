import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarDashboardComponent } from './components/car-dashboard/car-dashboard.component';
import { OverviewComponent } from './components/overview/overview.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ClimateDashboardComponent } from './components/climate-dashboard/climate-dashboard.component';
import { DiagnosisComponent } from './components/diagnosis/diagnosis.component';
import { CarWorkshopComponent } from './components/car-workshop/car-workshop.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { GasComponent } from './components/gas/gas.component';

const routes: Routes = [
  { path: '', redirectTo: '/cars/0', pathMatch: 'full' },
  { path: 'test', component: SearchBoxComponent },
  { path: 'cars/:carid', component: CarDashboardComponent },
  { path: 'cars/:carid/overview', component: OverviewComponent },
  { path: 'cars/:carid/settings', component: SettingsComponent },
  { path: 'cars/:carid/climate', component: ClimateDashboardComponent },
  { path: 'cars/:carid/diagnosis', component: DiagnosisComponent },
  { path: 'cars/:carid/diagnosis/help', component: CarWorkshopComponent },
  { path: 'cars/:carid/gas', component: GasComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
