import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CarWindow } from 'src/app/models/car';

@Component({
  selector: 'app-window-preview',
  templateUrl: './window-preview.component.html',
  styleUrls: ['./window-preview.component.css']
})
export class WindowPreviewComponent {

  get navigateToDetail(): string {
    return this.window.location.row.position + '/' + this.window.location.side;
  }

  @Input() window: CarWindow;
  @Output() brokenWindowEvent = new EventEmitter<CarWindow[]>();

  constructor() { }

  close(): void {
    if (this.window.isWorking()) {
      this.window.close();
    } else {
      this.brokenWindowEvent.emit([this.window]);
    }
  }

  open(): void {
    if (this.window.isWorking()) {
      this.window.open();
    } else {
      this.brokenWindowEvent.emit([this.window]);
    }
  }
}
