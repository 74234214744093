import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css']
})
export class ToggleButtonComponent implements OnInit {

  @Input() label: string;
  @Input() labelOn: string;
  @Input() labelOff: string;
  @Input() statusInput: boolean;
  @Output() statusOutput = new EventEmitter<boolean>();

  id: string;

  constructor() { }

  ngOnInit(): void {
    if (this.labelOff === undefined) {
      this.labelOff = 'Aus';
    }
    if (this.labelOn === undefined) {
      this.labelOn = 'An';
    }
    if (this.label === undefined || this.label === '') {
      this.id = this.createRandomId();
    } else {
      this.id = this.label;
    }
  }

  passStatusToParent(): void {
    this.statusOutput.emit(this.statusInput);
  }

  private createRandomId(): string {
    return '' + Math.floor(Math.random() * 1000000);
  }

}
