<div class="d-flex flex-column h-100">
  <div>
    <app-titlebar class="mb-3" name="Diagnose" back="/cars/{{carId}}"></app-titlebar>
  </div>
  <div class="mt-4 mb-4 container d-flex justify-content-between" style="font-size: 24px;"
    *ngIf="hasDiagnoseItems(); else no_diagnoseitems">
    <div *ngIf="diagnose.hasErrors(); else warning" class="text-error">
      Fehler gefunden
    </div>
    <div class="last-check d-flex flex-column justify-content-center">
      <div><img class="update mr-1" src="/assets/update.svg" (click)="updateDate()" />
        Zuletzt aktualisiert: {{captureDate()}}</div>
    </div>
  </div>
  <div class="overflow-auto scrollbar d-flex flex-column h-100" *ngIf="hasDiagnoseItems();">
    <div *ngFor="let diagnoseItem of car.diagnose.diagnoseItems; let i = index" style="color: var(--text);">
      <hr class="mt-0" style="border-top: 1px solid var(--text);width: 95%;" *ngIf="i===0">
      <app-diagnose-item [diagnoseItem]="diagnoseItem"></app-diagnose-item>
      <hr style="border-top: 1px solid var(--text);width: 95%;">
    </div>
  </div>
  <div class="d-flex flex-row mt-auto justify-content-center" *ngIf="hasDiagnoseItems();">
    <a [routerLink]="'/cars/' + carId + '/diagnosis/help'">
      <app-action class="align-self-center" icon="werkstatt" name="Hilfe rufen">
      </app-action>
    </a>
  </div>
</div>

<!-- ng-templates -->
<ng-template #no_diagnoseitems>
  <div class="d-flex flex-column justify-content-center h-100">
    <img class="img-ok" src="/assets/thump-up.svg" />
    <div class="mt-3 text-ok" style="font-size: 24px;">
      Alles gut
    </div>
    <div class="text-ok" style="font-size: 12px;">
      Es wurden keine Fehler festgestellt
    </div>
  </div>
</ng-template>
<ng-template #warning>
  <div class="text-warning">
    Warnung gefunden
  </div>
</ng-template>