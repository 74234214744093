<div class="d-flex flex-column h-100">
  <app-titlebar name="Einstellungen" back="/cars/{{carId}}" [showSettingsIcon]="false"></app-titlebar>
  <div class="d-flex flex-column m-3">
    <!-- Persönliche Daten -->
    <div class="d-flex flex-column">
      <div class="category-headline">Persönliche Daten</div>
      <!-- Name -->
      <div class="d-flex flex-row row">
        <span class="label">Name</span>
        <input [(ngModel)]="profile.name"/>
      </div>
      <!-- Telefonnummer -->
      <div class="d-flex flex-row row">
        <span class="label">Telefonnummer</span>
        <input [(ngModel)]="profile.phone"/>
      </div>
      <!-- Favorite Werkstatt -->
      <div class="d-flex flex-row row">
        <span class="label">Lieblingswerkstatt</span>
        <app-search-box [preSelectIndex]="this.service.getCarById(this.carId).profile.favoriteWorkshopId"
          [displayNames]="createDisplayNames()" (selectedIndex)="handleSearchIndex($event)">
        </app-search-box>
      </div>
      <!-- Info Text -->
      <div class="font-italic text-center mt-2">Die persönlichen Daten werden benötigt, um bei Bedarf Kontakt mit
        einer Werkstatt aufzunehmen.
      </div>
    </div>
    <hr style="border-top: 1px solid var(--text); width: 100%;">
    <!-- Warnungen -->
    <div class="d-flex flex-column">
      <div class="category-headline">Warnungen</div>
      <!-- Reichweite -->
      <div class="d-flex flex-row row">
        <img src="/assets/zapfsaeule.svg" style="height: 20px;" />
        <span class="label">{{getReichweite()}}</span>
        <input [value]="getReichweitenValue().display()[0]" (blur)="getReichweitenValue().set($event.target.value)" />
      </div>
      <!-- Reifendruck -->
      <div class="d-flex flex-row row">
        <img src="/assets/tire.svg" style="height: 20px;" />
        <span class="label">{{getReifendruck()}}</span>
        <input [value]="getReifendruckValue().display()[0]" (blur)="getReifendruckValue().set($event.target.value)" />
      </div>
      <div class="d-flex flex-column ml-3 category-content">
      </div>
      <hr style="border-top: 1px solid var(--text); width: 100%;">
      <!-- Sonstiges -->
      <div class="d-flex flex-column">
        <div class="category-headline">Sonstiges</div>
        <!-- Theme -->
        <div class="d-flex flex-row row">
          <span class="label">Design</span>
          <div ngbDropdown>
            <button class="btn btn-sm" id="dropDownMenu"
              ngbDropdownToggle>{{themeService.getActiveTheme().name}}</button>
            <div ngbDropdownMenu aria-labelledby="dropDownMenu" style="background-color: var(--dropdown-background)">
              <div ngbDropdownItem *ngFor="let theme of getThemes()" (click)="setTheme(theme)" class="item">
                {{theme}}
              </div>
            </div>
          </div>
        </div>
        <!-- Theme -->
        <div class="d-flex flex-row row">
          <span class="label">{{getTemperatur()}}</span>
          <div ngbDropdown>
            <button class="btn btn-sm" id="dropdown-einheit"
              ngbDropdownToggle>{{getTemperaturValue().display()[0]}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdown-einheit"
              style="background-color: var(--dropdown-background)">
              <button ngbDropdownItem *ngFor="let einheit of getTemperaturValue().display()" [value]="einheit"
                (click)="getTemperaturValue().set($event.target.value)" class="item">{{einheit}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
