<div class="d-flex flex-row titlebar w-100" style="height: 44px;">
  <div class="w-25 text-left">
    <a class="btn arrow mt-1 chevron left" [routerLink]="back"></a>
  </div>
  <div class="w-50 text-center align-self-center">
    <span>{{name}}</span>
  </div>
  <div *ngIf="showSettingsIcon" class="w-25 align-self-center" style="text-align: right; margin-right: 15px;">
    <a class="settings" [routerLink]="'/cars/'+carId+'/settings'">
      <img class="settings-image" src="/assets/settings.svg" />
    </a>
  </div>
</div>
