import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorType } from 'src/app/models/diagnose';
import { Message } from 'src/app/models/message';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent {

  @Input() errorMessages: Message[];
  @Input() warningMessages: Message[];
  @Input() infoMessages: Message[];

  carId: number;

  errorType = ErrorType;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });
  }

  noMessages(): boolean {
    return this.errorMessages.length + this.warningMessages.length + this.infoMessages.length === 0;
  }
}
