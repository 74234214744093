import { Injectable } from '@angular/core';
import { Message} from '../models/message';
import { Car, CarWindow, CarDoor } from '../models/car';
import { FuelSystem, DrivingRange, FuelLevel } from '../models/information-settings-items/fuel-system';
import { Location, Row, Side } from '../models/location';
import { Percent } from '../models/percent';

@Injectable({
  providedIn: 'root'
})
export class CarService {

  readonly cars: Car[] = [];

  constructor() {
    this.cars.push(this.createMockCar1());
    this.cars.push(this.createMockCar2());
  }

  getCarById(id: number): Car {
    return this.cars[id];
  }

  private createMockCar1(): Car {
    const car = new Car('Donalds Auto');

    car.profile.name = 'Donald Duck';
    car.profile.phone = '0176 9129127';
    car.profile.favoriteWorkshopId = 4;

    car.addWindow(this.createMockWindow(0, Side.Left, 0));
    car.addWindow(this.createMockWindow(0, Side.Right, 100));
    car.addWindow(this.createMockWindow(1, Side.Left, 80));
    car.addWindow(this.createMockWindow(1, Side.Right, 24));

    car.addDoor(this.createMockDoor(0, Side.Left, false));
    car.addDoor(this.createMockDoor(0, Side.Right, true));
    car.addDoor(this.createMockDoor(1, Side.Left, true));
    car.addDoor(this.createMockDoor(1, Side.Right, false));

    car.addDoor(this.createMockDoor(2, Side.Back, true));

    return car;
  }

  private createMockCar2(): Car {
    const car = new Car('Dagoberts Auto');

    car.profile.name = 'Dagobert Duck';
    car.profile.phone = '0172 58231321';
    car.profile.favoriteWorkshopId = 5;

    car.addWindow(this.createMockWindow(0, Side.Left, 0));
    car.addWindow(this.createMockWindow(0, Side.Right, 100));
    car.addWindow(this.createMockWindow(1, Side.Left, 80));
    car.addWindow(this.createMockWindow(1, Side.Right, 24));

    car.addDoor(this.createMockDoor(0, Side.Left, false));
    car.addDoor(this.createMockDoor(0, Side.Right, true));
    car.addDoor(this.createMockDoor(1, Side.Left, true));
    car.addDoor(this.createMockDoor(1, Side.Right, false));

    car.addDoor(this.createMockDoor(2, Side.Back, true));

    return car;
  }

  private createMockWindow(row: number, side: Side, positionInPercent: number): CarWindow {
    return new CarWindow(new Location(new Row(row), side), new Percent(positionInPercent));
  }

  private createMockDoor(row: number, side: Side, isLocked: boolean): CarDoor {
    return new CarDoor(new Location(new Row(row), side), isLocked);
  }
}
