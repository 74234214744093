import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WindowPreviewComponent } from './components/window-preview/window-preview.component';
import { OverviewComponent } from './components/overview/overview.component';
import { CarDashboardComponent } from './components/car-dashboard/car-dashboard.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitlebarComponent } from './components/titlebar/titlebar.component';
import { ActionComponent } from './components/action/action.component';
import { InformationDashboardComponent } from './components/information-dashboard/information-dashboard.component';
import { InformationDashboardItemComponent } from './components/information-dashboard-item/information-dashboard-item.component';
import { DoorPreviewComponent } from './components/door-preview/door-preview.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsItemComponent } from './components/settings-item/settings-item.component';
import { ClimateDashboardComponent } from './components/climate-dashboard/climate-dashboard.component';
import { TargetIndoorConfigurationComponent } from './components/target-indoor-configuration/target-indoor-configuration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { ThemeModule } from './theme/theme.module';
import { darkTheme } from './theme/dark-theme';
import { greenTheme } from './theme/green-theme';
import { DiagnosisComponent } from './components/diagnosis/diagnosis.component';
import { CarWorkshopComponent } from './components/car-workshop/car-workshop.component';
import { FailureComponent } from './components/failure/failure.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DiagnoseItemComponent } from './components/diagnose-item/diagnose-item.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { GasComponent } from './components/gas/gas.component';

@NgModule({
  declarations: [
    AppComponent,
    WindowPreviewComponent,
    OverviewComponent,
    CarDashboardComponent,
    NavigationComponent,
    TitlebarComponent,
    ActionComponent,
    InformationDashboardComponent,
    InformationDashboardItemComponent,
    DoorPreviewComponent,
    MessageBoxComponent,
    SettingsComponent,
    SettingsItemComponent,
    ClimateDashboardComponent,
    TargetIndoorConfigurationComponent,
    ScheduleComponent,
    ToggleButtonComponent,
    DiagnosisComponent,
    DiagnoseItemComponent,
    CarWorkshopComponent,
    FailureComponent,
    SearchBoxComponent,
    GasComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    ThemeModule.forRoot({
      themes: [darkTheme, greenTheme],
      active: 'dark'
    }),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
