import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Profile } from 'src/app/models/car';
import { FuelSystem } from 'src/app/models/information-settings-items/fuel-system';
import { Configurable, SettingsConfigurable } from 'src/app/models/information-settings-items/interfaces';
import { CarWorkshopService } from 'src/app/services/car-workshop.service';
import { CarService } from 'src/app/services/car.service';
import { ThemeService } from 'src/app/theme/theme.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  carId: number;
  items: SettingsConfigurable[];

  profile: Profile;
  private favoriteId: number;

  private _showDetails = true;

  constructor(
    private route: ActivatedRoute,
    public service: CarService,
    readonly themeService: ThemeService,
    private workshopService: CarWorkshopService
  ) {
    route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });

    this.items = this.service.getCarById(this.carId).settingsItems;

    this.profile = this.service.getCarById(this.carId).profile;
  }

  ngOnInit(): void {
    this.favoriteId = this.service.getCarById(this.carId).profile.favoriteWorkshopId;
  }

  showDetails(): boolean {
    return this._showDetails;
  }

  toggleDetails(): void {
    this._showDetails = !this._showDetails;
  }

  setTheme(name: string): void {
    this.themeService.setTheme(name);
  }

  getThemes(): string[] {
    return ['dark', 'green'];
  }

  createDisplayNames(): string[] {
    return this.workshopService.getMockWorkshops().map(workshop => workshop.display());
  }

  handleSearchIndex(index: number): void {
    this.service.getCarById(this.carId).profile.favoriteWorkshopId = index;
  }


  // temp helper methods

  getReichweite(): string {
    return this.items.find(item => item.getName() === 'Tank').getSettingsMap().keys().next().value;
  }

  getReichweitenValue(): Configurable {
    return this.items.find(item => item.getName() === 'Tank').getSettingsMap().get('Reichweitenwarnung unter');
  }

  getReifendruck(): string {
    return this.items.find(item => item.getName() === 'Reifendruck').getSettingsMap().keys().next().value;
  }

  getReifendruckValue(): Configurable {
    return this.items.find(item => item.getName() === 'Reifendruck').getSettingsMap().get('Reifendruckwarnung unter');
  }

  getTemperatur(): string {
    return this.items.find(item => item.getName() === 'Temperatur').getSettingsMap().keys().next().value;
  }

  getTemperaturValue(): Configurable {
    return this.items.find(item => item.getName() === 'Temperatur').getSettingsMap().get('Einheit');
  }

}
