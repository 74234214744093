<div class="container">
    <div class="row">
        <div class="col-8 title d-flex flex-column justify-content-center">
            {{diagnoseItem.title}}
        </div>
        <div class="col-2 d-flex flex-column justify-content-center">
            <img *ngIf="diagnoseItem.errorType === errorType.error" style="height : 20px;" src="/assets/warning-triangle-red.svg" />
            <img *ngIf="diagnoseItem.errorType === errorType.warning" style="height : 20px;" src="/assets/warning-triangle-orange.svg" />
        </div>
        <div class="col-2">
            <a *ngIf="!diagnoseItem.showDetails()" class="btn arrow chevron down"
                (click)="diagnoseItem.toggleShowDetails()"></a>
                <a *ngIf="diagnoseItem.showDetails()" class="btn arrow chevron up"
                (click)="diagnoseItem.toggleShowDetails()"></a>
            </div>
        </div>
        <div *ngIf="diagnoseItem.showDetails()">
        <div class="mt-3 p-1 d-flex flex-column justify-content-center errorCode">
            Fehlercode: {{diagnoseItem.errorCode}}
        </div>
        <div class="mt-3" style="font-size: small;" *ngIf="diagnoseItem.errorType === errorType.error">
            Fehler gefunden am: {{diagnoseItem.date}}
        </div>
        <div class="mt-3" style="font-size: small;" *ngIf="diagnoseItem.errorType === errorType.warning">
            Warnung gefunden am: {{diagnoseItem.date}}
        </div>
        <div class="mt-3" style="font-size: small;">
            {{diagnoseItem.text}}
        </div>
        <div *ngIf="diagnoseItem.errorType === errorType.error" class="mt-3" style="font-size: small; color: red;">
            Kontaktieren Sie sofort die Werkstatt!
        </div>
    </div>
</div>