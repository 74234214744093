<a [routerLink]="destination">
  <div class="rounded outside">
    <div class="img-above">
      <span *ngIf="badgeStr.length > 0" class="badge badge-danger bdg-top-right-visible">{{badgeStr}}</span>
      <span *ngIf="badgeStr.length === 0" class="bdg-top-right-invisible badge">{{badgeStr}}</span>
      <img src="/assets/{{icon}}.svg" />
    </div>
    <div class="text-below">
      <span class="navigation-name">
        {{name}}
      </span>
    </div>
  </div>
</a>
