import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClimateControl } from 'src/app/models/information-settings-items/climate-control';
import { CarService } from 'src/app/services/car.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  @Input() id: number;

  carId: number;

  climateControl: ClimateControl = new ClimateControl();

  constructor(
    private route: ActivatedRoute,
    private service: CarService
  ) {
    route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });
    this.climateControl = service.getCarById(this.carId).climateControl;
  }

  ngOnInit(): void {
  }

}
