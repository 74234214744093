<div class="d-flex flex-column">
  <div>
    <div class="btn btn-circle btn-success mb-2" (click)="open()" *ngIf="window.isClosed(); else closedblock">
      <img src="/assets/window-closed.svg" height="30px" />
    </div>
    <ng-template #closedblock>
      <div class="btn btn-circle btn-danger mb-2" (click)="close()">
        <img src="/assets/window-open.svg" height="30px" />
      </div>
    </ng-template>
  </div>
</div>