import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent {

  @Input() icon: string;
  @Input() name: string;
  @Output() clicked = new EventEmitter();

  constructor() {
  }

  handleClick(): void {
    this.clicked.emit();
  }
}
