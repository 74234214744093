import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Car } from 'src/app/models/car';
import { Diagnose } from 'src/app/models/diagnose';
import { CarService } from 'src/app/services/car.service';

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.css']
})
export class DiagnosisComponent {

  carId: number;
  car: Car;
  diagnose: Diagnose;
  date: string;
  diagnoseCount: number;

  constructor(
    private route: ActivatedRoute,
    private service: CarService
  ) {
    route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });
    this.car = service.getCarById(this.carId);
    this.diagnose = this.car.diagnose;
    this.diagnoseCount = this.diagnoseItemsCount();
    this.updateDate();
  }

  hasDiagnoseItems(): boolean {
    return this.diagnose.diagnoseItems.length > 0;
  }

  diagnoseItemsCount(): number {
    return this.diagnose.diagnoseItems.length;
  }

  captureDate(): string {
    if (this.diagnoseItemsCount() !== this.diagnoseCount) {
      this.diagnoseCount = this.diagnoseItemsCount();
      this.updateDate();
    }
    return this.date;
  }

  updateDate(): void {
    this.date = new Date().toLocaleString();
  }
}
