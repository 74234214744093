<div class="d-flex flex-column main" style="text-align:center;">
  <button class="btn d-flex flex-column" (click)="handleClick()">
    <div class="img-above align-self-center">
      <img src="/assets/{{icon}}.svg" />
    </div>
    <div class="text-below align-self-center">
      <span class="actionName">
        {{name}}
      </span>
    </div>
  </button>
</div>
