import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Car } from 'src/app/models/car';
import { ClimateControl } from 'src/app/models/information-settings-items/climate-control';
import { CarService } from 'src/app/services/car.service';

@Component({
  selector: 'app-climate-dashboard',
  templateUrl: './climate-dashboard.component.html',
  styleUrls: ['./climate-dashboard.component.css']
})
export class ClimateDashboardComponent implements OnInit {

  carId: number;
  car: Car;
  currentIndoorTemp: string;
  currentOutdoorTemp: string;
  currentTargetTemp: string;
  climateControl: ClimateControl;
  active: boolean;

  constructor(
    private route: ActivatedRoute,
    private service: CarService
  ) {
    route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });
    this.car = service.getCarById(this.carId);
  }

  ngOnInit(): void {
    this.currentIndoorTemp = this.car.climateControl.currentIndoorTemp.display()[0];
    this.currentOutdoorTemp = this.car.climateControl.currentOutdoorTemp.display()[0];
    this.currentTargetTemp = this.car.climateControl.targetIndoorTemp.display()[0];
    this.climateControl = this.car.climateControl;
  }

  handleSwitchClimateActive(status: boolean): void {
    this.service.getCarById(this.carId).climateControl.active = status;
  }

  handleSwitchPeriodic(status: boolean, id: number): void {
    this.service.getCarById(this.carId).climateControl.getTimeConfiguration(id).autoConfig = status;
  }

  handleNewTargetTemperature(): void {
  }

  createActiveFeedback(): string {
    if (this.car.climateControl.currentIndoorTemp.getValue() < this.car.climateControl.targetIndoorTemp.getValue()) {
      return 'Das Auto wird gerade auf ' + this.car.climateControl.targetIndoorTemp.display() + ' geheizt';
    } else if (this.car.climateControl.currentIndoorTemp.getValue() > this.car.climateControl.targetIndoorTemp.getValue()) {
      return 'Das Auto wird gerade auf ' + this.car.climateControl.targetIndoorTemp.display() + ' gekühlt';
    } else {
      return '';
    }
  }

  createTimeplanFeedback(id: number): string {
    return 'Das Auto wird an den ausgewählten Tagen um '
      + this.car.climateControl.getTimeConfiguration(id).startTime.toString()
      + ' Uhr auf ' + this.car.climateControl.targetIndoorTemp.display()
      + ' temperiert.';
  }

  indoorIsSameAsTarget(): boolean {
    return this.car.climateControl.targetIndoorTemp.getValue() === this.car.climateControl.currentIndoorTemp.getValue();
  }

}
