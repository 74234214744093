<div class="container">
  <div class="row mb-3">
    <div class="col-6 title">
      {{name}}
      <span *ngIf="!showDetails && item.getBadgeCount() > 0" class="badge badge-danger ml-2">
        {{item.getBadgeCount()}}
      </span>
    </div>
    <div class="col-4">
      <img src="/assets/{{icon}}.svg" height="40px" />
    </div>
    <div class="col-2">
      <a *ngIf="!showDetails" class="btn arrow chevron down" (click)="toggleShowDetails()"></a>
      <a *ngIf="showDetails" class="btn arrow chevron up" (click)="toggleShowDetails()"></a>
    </div>
  </div>
  <div *ngIf="showDetails">
    <div class="row" *ngFor="let entry of item.getDisplayMap() | keyvalue">
      <div class="col-6 text">{{entry.key}}</div>
      <div class="col-6" style="color:{{entry.value.displayColor}}">{{entry.value.displayString}}</div>
    </div>
  </div>
</div>