<div class="d-flex flex-row">
  <div class="d-flex flex-column form-check form-check-inline w-100"
    *ngFor="let configuration of climateControl.getTimeConfiguration(id).configuration">
    <label class="form-check-label" for="checkBox">{{configuration.weekDay.slice(0,2)}}</label>
    <input class="form-check-input" type="checkbox" id="checkBox" [(ngModel)]="configuration.active">
  </div>
</div>
<div class="d-flex justify-content-center my-3">
  <input type="time" id="time" [(ngModel)]="climateControl.getTimeConfiguration(id).startTime">
</div>
