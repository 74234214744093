import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.css']
})
export class TitlebarComponent {

  @Input() back: string;
  @Input() name: string;
  @Input() showSettingsIcon: boolean;

  carId: number;

  constructor(
    private route: ActivatedRoute
  ) {
    route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
    });
    if (this.showSettingsIcon === undefined) {
      this.showSettingsIcon = true;
    }
  }

}
