import { Percent } from '../percent';
import { Configurable, DisplayInfos, InformationDashboardDisplayable, InputType, SettingsConfigurable } from './interfaces';

export class FuelSystem implements InformationDashboardDisplayable, SettingsConfigurable {
    private _showSettingsDetails = true;
    private _showInformationDetails = true;
    public maxLevelLiters: FuelLevel;

    constructor(
        private _levelLiters: FuelLevel,
        private _levelPercent: Percent,
        private _currentRange: DrivingRange,
        private _rangeLimit: DrivingRange
    ) {
        this.maxLevelLiters = new FuelLevel(this._levelLiters._levelInLiters);
    }

    getName(): string {
        return 'Tank';
    }

    getIcon(): string {
        return 'zapfsaeule';
    }

    getDisplayMap(): Map<string, DisplayInfos> {
        const map = new Map<string, DisplayInfos>();

        map.set('Füllstand', new DisplayInfos([this._levelPercent.displayValue() + ' (' + this._levelLiters.displayLevelInLiters() + ')'], '#FFFFFF'));
        map.set('Reichweite', new DisplayInfos([this.isRangeLow() ? this._currentRange.displayRangeInKilometers() + ' (niedrig)' : this._currentRange.displayRangeInKilometers()], this.isRangeLow() ? '#FFA500' : '#FFFFFF'));
        map.set('Reichweitenwarnung', new DisplayInfos([this._rangeLimit.displayRangeInKilometers()], '#FFFFFF'));

        return map;
    }

    toggleShowInformationDetails(): void {
        this._showInformationDetails = !this._showInformationDetails;
    }

    showInformationDetails(): boolean {
        return this._showInformationDetails;
    }

    getBadgeCount(): number {
        return this.isRangeLow() ? 1 : 0;
    }

    getSettingsMap(): Map<string, Configurable> {
        const map = new Map<string, Configurable>();

        map.set('Reichweitenwarnung unter', this._rangeLimit);

        return map;
    }

    toggleShowSettingsDetails(): void {
        this._showSettingsDetails = !this._showSettingsDetails;
    }

    showSettingsDetails(): boolean {
        return this._showSettingsDetails;
    }

    get levelLiters(): FuelLevel {
        return this._levelLiters;
    }

    set levelLiters(levelLiters: FuelLevel) {
        this._levelLiters = levelLiters;
    }

    get levelPercent(): Percent {
        return this._levelPercent;
    }

    set levelPercent(level: Percent) {
        this._levelPercent = level;
    }

    get currentRange(): DrivingRange {
        return this._currentRange;
    }

    set currentRange(range: DrivingRange) {
        this._currentRange = range;
    }

    get rangeLimit(): DrivingRange {
        return this._rangeLimit;
    }

    get isLowFuelLevel(): boolean {
        return this._currentRange.isBelowOrEqual(this._rangeLimit);
    }

    get range(): DrivingRange {
        return this._currentRange;
    }

    set range(range: DrivingRange) {
        this._currentRange = range;
    }

    private isRangeLow(): boolean {
        return this._currentRange.isBelowOrEqual(this._rangeLimit);
    }
}

export class FuelLevel {
    constructor(
        public _levelInLiters: number
    ) { }

    displayLevelInLiters(): string {
        return this._levelInLiters + ' l';
    }
}

export class DrivingRange implements Configurable {
    constructor(
        private _rangeInMeters: number
    ) { }

    getInputType(): InputType {
        return InputType.Text;
    }

    display(): string[] {
        return [this.displayRangeInKilometers()];
    }

    set(value: string): void {
        value = value.replace('km', '');
        value = value.replace(' ', '');
        this.rangeInMeters = +value * 1000;
    }

    get rangeInMeters(): number {
        return this._rangeInMeters;
    }

    set rangeInMeters(rangeInMeters: number) {
        this._rangeInMeters = rangeInMeters;
    }

    displayRangeInMeters(): string {
        return this.rangeInMeters + ' m';
    }

    displayRangeInKilometers(): string {
        return (this.rangeInMeters / 1000) + ' km';
    }

    isBelowOrEqual(range: DrivingRange): boolean {
        return this._rangeInMeters <= range._rangeInMeters;
    }
}
