import { Component, Input } from '@angular/core';
import { CarDoor } from 'src/app/models/car';

@Component({
  selector: 'app-door-preview',
  templateUrl: './door-preview.component.html',
  styleUrls: ['./door-preview.component.css']
})
export class DoorPreviewComponent {

  @Input() door: CarDoor;

  constructor() {
  }

  lock(): void {
    this.door.lock();
  }

  unlock(): void {
    this.door.unlock();
  }
}
