import { Component, Input} from '@angular/core';
import { DiagnoseItem, ErrorType } from 'src/app/models/diagnose';

@Component({
  selector: 'app-diagnose-item',
  templateUrl: './diagnose-item.component.html',
  styleUrls: ['./diagnose-item.component.css']
})
export class DiagnoseItemComponent {

  @Input() diagnoseItem: DiagnoseItem;

  errorType = ErrorType;

  constructor() { }

}
