export class GasStation {
    public price: string;
    public p: number;
    public color: string;

    constructor(
        public id: number,
        public name: string,
        public phone: string,
        public city: string,
        public plz: string,
        public street: string,
        public streetAppendix: string,
        public distanceInMeter: number,
        public url: URL
    ) {
        this.p = +(1 + 0.5 * Math.random()).toFixed(2);
        this.price = this.p.toFixed(2) + ' €/l';

        if (this.p <= 1.16) {
            this.color = '#90ee90';
        } else if (this.p > 1.16 && this.p <= 1.32) {
            this.color = '#ffff00';
        } else {
            this.color = '#ff0000';
        }
    }

    getDistance(): number {
        return this.distanceInMeter;
    }

    getDistanceDisplay(): string {
        return this.distanceInMeter < 1000
            ? ((Math.round(this.distanceInMeter / 10) * 10) + ' m')
            : ((Math.round(this.distanceInMeter / 100) / 10) + ' km');
    }

    display(): string {
        return this.name + ', '
            + this.plz + ' '
            + this.city + ', '
            + this.street + ' '
            + this.streetAppendix;
    }

    displayWithDistance(): string {
        return this.getDistanceDisplay() + ', '
            + this.name + ', '
            + this.plz + ' '
            + this.city + ', '
            + this.street + ' '
            + this.streetAppendix;
    }

}
