<div class="d-flex flex-column justify-content-between" style="height:100%">
  <!-- car selection -->
  <div>
    <div class="d-flex flex-column justify-content-center row title w-100" style="height: 44px">
      <div class="col-2">
      </div>
      <div ngbDropdown class="w-100 col-8">
        <button class="btn btn-primary w-100 " id="dropdownMenu" ngbDropdownToggle>{{selectedCar.name}}</button>
        <div class="w-100" ngbDropdownMenu aria-labelledby="dropdownMenu"
          style="background-color: var(--dropdown-background)">
          <a class="item" ngbDropdownItem *ngFor="let car of cars" [routerLink]="'/cars/' + car.id">
            {{car.name}}
          </a>
        </div>
      </div>
      <div class="col-2" style="text-align: right;">
        <a class="settings" [routerLink]="'/cars/'+carId+'/settings'">
          <img class="settings-image" src="/assets/settings.svg" />
        </a>
      </div>
    </div>
  </div>
  <!-- navigation -->
  <div class="container py-2 mb-auto">
    <div class="row">
      <app-navigation class="col-4 grid-element" icon="lock-closed" name="Verriegelung"
        destination="/cars/{{carId}}/overview" [badgeStr]="overviewBadge"></app-navigation>
      <app-navigation class="col-4 grid-element" icon="thermometer" name="Temperatur"
        destination="/cars/{{carId}}/climate" badgeStr=""></app-navigation>
      <app-navigation class="col-4 grid-element" icon="zapfsaeule" name="Tanken" destination="/cars/{{carId}}/gas"
        [badgeStr]="gasBadge"></app-navigation>
    </div>
    <div class="row">
      <app-navigation class="col-4 grid-element" icon="diagnosis" name="Diagnose"
        destination="/cars/{{carId}}/diagnosis" [badgeStr]="diagnosisBadge">
      </app-navigation>
    </div>
  </div>
  <!-- messages -->
  <div class="container py-2 h-100 overflow-auto scrollbar">
    <div class="row">
      <div class="col-12">
        <app-message-box [errorMessages]="selectedCar.errorMessages" [warningMessages]="selectedCar.warningMessages"
          [infoMessages]="selectedCar.infoMessages" class="text-center">
        </app-message-box>
      </div>
    </div>
  </div>
  <!-- actions -->
  <div class="container">
    <div style="margin-top: 5; margin-bottom: 0px;" class="row">
      <app-action class="action-element" icon="lock-closed" name="Auto entriegeln" (clicked)="unlockAllDoors()"
        *ngIf="selectedCar.isShutdown(); else locks_closed">
      </app-action>
      <app-action class="action-element" icon="thermometer_off" name="Heizung anschalten"
        *ngIf="!climateOn; else climate_on" (clicked)="toggleClimate()">
      </app-action>
    </div>
  </div>
</div>

<ngb-alert role="alert" type="danger" *ngIf="windowAlertText.length > 0" (closed)="closeAlert()"
  class="show align-self-center padd alert-danger fade alert-dismissible" ng-reflect-type="danger"><a
    class="mr-5 alert-text" [routerLink]="'/cars/' + carId + '/diagnosis'">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="mr-1 bi bi-arrow-right-square-fill" stroke="#FF0000"
      fill="#F6CECE" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 8.5a.5.5 0 0 1 0-1h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5z" />
    </svg>
    {{windowAlertText}}
  </a>
</ngb-alert>

<!-- ng-templates -->
<ng-template #locks_closed>
  <app-action class="action-element" icon="lock-open" name="Auto verriegeln" (clicked)="shutdown()">
  </app-action>
</ng-template>
<ng-template #climate_on>
  <app-action class="action-element" icon="thermometer_on" name="Heizung ausschalten" (clicked)="toggleClimate()">
  </app-action>
</ng-template>