import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Car, CarWindow } from 'src/app/models/car';
import { DiagnoseItem, ErrorType } from 'src/app/models/diagnose';
import { DrivingRange, FuelLevel } from 'src/app/models/information-settings-items/fuel-system';
import { Light } from 'src/app/models/information-settings-items/lights';
import { Tire } from 'src/app/models/information-settings-items/tire-pressure';
import { Message } from 'src/app/models/message';
import { Percent } from 'src/app/models/percent';
import { CarService } from 'src/app/services/car.service';
import { CarDashboardComponent } from '../car-dashboard/car-dashboard.component';

@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.css']
})
export class FailureComponent implements OnInit {
  carId: number;
  selectedCar: Car;

  fuelFailure = false;
  windowFailure = false;

  window: CarWindow;
  windowMessage: DiagnoseItem;

  tireFailure = false;
  tire: Tire;
  tireMessage: DiagnoseItem;

  lightFailure = false;
  light: Light;
  lightMessage: DiagnoseItem;

  lambdaFailure = false;
  lambdaMessage: DiagnoseItem;

  catalystFailure = false;
  catalystMessage: DiagnoseItem;

  iceFailure = false;
  iceMessage: Message;

  constructor(
    private route: ActivatedRoute,
    private service: CarService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
      this.selectedCar = this.service.getCarById(this.carId);
    });
  }

  toggleFuelFailure(): void {
    if (!this.fuelFailure) {
      this.selectedCar.fuelSystem.levelLiters = new FuelLevel(4.5);
      this.selectedCar.fuelSystem.levelPercent = new Percent(9);
      this.selectedCar.fuelSystem.currentRange = new DrivingRange(69000);
    } else {
      this.selectedCar.fuelSystem.levelLiters = new FuelLevel(50);
      this.selectedCar.fuelSystem.levelPercent = new Percent(100);
      this.selectedCar.fuelSystem.currentRange = new DrivingRange(700010);
    }
    this.fuelFailure = !this.fuelFailure;
    this.updateCarDashboards();
  }

  toggleWindowFailure(): void {
    if (!this.windowFailure) {
      this.window = this.selectedCar.getRandomWindow();
      this.windowMessage = this.createMockErrorWindow(this.window);
      this.selectedCar.addDiagnoseItem(this.windowMessage);
      this.window.break();
    } else {
      this.window.repair();
      this.selectedCar.removeDiagnoseItem(this.windowMessage);
      this.window = null;
      this.windowMessage = null;
    }
    this.windowFailure = !this.windowFailure;
    this.updateCarDashboards();
  }

  createMockErrorWindow(window: CarWindow): DiagnoseItem {
    return new DiagnoseItem('Fenster defekt', 'Das ' + window.display() + ' lässt sich nicht schließen. Tauschen Sie die Sicherung aus oder kontaktieren Sie die Werkstatt.', 'B118E', ErrorType.warning);
  }

  toggleTireFailure(): void {
    if (!this.tireFailure) {
      this.tire = this.selectedCar.tirePressure.getRandomTire();
      this.tire.brake();
      this.tireMessage = this.createMockErrorTire(this.tire);
      this.selectedCar.addDiagnoseItem(this.tireMessage);
    } else {
      this.tire.repair();
      this.selectedCar.removeDiagnoseItem(this.tireMessage);
      this.tire = null;
      this.tireMessage = null;
    }
    this.tireFailure = !this.tireFailure;
    this.updateCarDashboards();
  }

  createMockErrorTire(tire: Tire): DiagnoseItem {
    return new DiagnoseItem('Reifendruck niedrig', 'Der aktuelle Reifendruck ' + tire.position.toLowerCase() + ' beträgt ' + tire.pressure.value + ' bar und ist damit niedriger als der minimale Reifendruck. Ändern Sie den minimalen Reifendruck in den Einstellungen oder pumpen Sie den Reifen auf.', 'B2868', ErrorType.warning);
  }

  toggleLightFailure(): void {
    if (!this.lightFailure) {
      this.light = this.selectedCar.lightSystem.getRandomLight();
      this.light.brake();
      this.lightMessage = this.createMockErrorLight(this.light);
      this.selectedCar.addDiagnoseItem(this.lightMessage);
    } else {
      this.light.repair();
      this.selectedCar.removeDiagnoseItem(this.lightMessage);
      this.light = null;
      this.lightMessage = null;
    }
    this.lightFailure = !this.lightFailure;
    this.updateCarDashboards();
  }

  createMockErrorLight(light: Light): DiagnoseItem {
    return new DiagnoseItem('Licht defekt', 'Das Licht ' + light.position.toLowerCase() + ' ist defekt. Tauschen Sie die Glühbirne aus.', 'B2391', ErrorType.warning);
  }

  toggleLambdaFailure(): void {
    if (!this.lambdaFailure) {
      this.lambdaMessage = this.createMockErrorLambdasonde();
      this.selectedCar.addDiagnoseItem(this.lambdaMessage);
    } else {
      this.selectedCar.removeDiagnoseItem(this.lambdaMessage);
      this.lambdaMessage = null;
    }
    this.lambdaFailure = !this.lambdaFailure;
    this.updateCarDashboards();
  }

  createMockErrorLambdasonde(): DiagnoseItem {
    return new DiagnoseItem('Lambdasonde defekt', 'Beheizte Lambdasonde 2, Zylinderreihe 1 - Spannung zu niedrig.', 'P0137', ErrorType.error);
  }

  toggleCatalystFailure(): void {
    if (!this.catalystFailure) {
      this.catalystMessage = this.createMockErrorKatalysator();
      this.selectedCar.addDiagnoseItem(this.catalystMessage);
    } else {
      this.selectedCar.removeDiagnoseItem(this.catalystMessage);
      this.catalystMessage = null;
    }
    this.catalystFailure = !this.catalystFailure;
    this.updateCarDashboards();
  }

  createMockErrorKatalysator(): DiagnoseItem {
    return new DiagnoseItem('Katalysator defekt', 'Katalysatoranlage, Zylinderreihe 1 - Wirkungsgrad unter Schwellenwert.', 'P0420', ErrorType.error);
  }

  toggleIceFailure(): void {
    if (!this.iceFailure) {
      if (!this.service.getCarById(this.carId).climateControl.getTimeConfiguration(1).autoConfig &&
          !this.service.getCarById(this.carId).climateControl.getTimeConfiguration(2).autoConfig) {
        this.iceMessage = new Message('Frost vorhergesagt', '', 'climate');
        this.selectedCar.addInfoMessage(this.iceMessage);
      }
    } else {
      if (this.iceMessage !== null) {
        this.selectedCar.removeInfoMessage(this.iceMessage);
        this.iceMessage = null;
      }
    }
    this.iceFailure = !this.iceFailure;
    this.updateCarDashboards();
  }

  private updateCarDashboards(): void {
    CarDashboardComponent.updateCarDashboards();
  }
}
