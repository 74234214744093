export class Message {
    private static ID = 0;

    readonly date: string;
    readonly id: number = Message.ID++;

    constructor(
        readonly text: string,
        readonly icon: string,
        readonly destination: string,
    ) {
        this.date = Date.toLocaleString();
    }
}
