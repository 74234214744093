import { DisplayInfos, InformationDashboardDisplayable } from './interfaces';

export class LightSystem implements InformationDashboardDisplayable {
    private _showInformationDetails = true;

    constructor(
        public lights: Light[] = [
            new Light('Vorne links'),
            new Light('Vorne rechts'),
            new Light('Hinten links'),
            new Light('Hinten rechts')
        ]
    ) { }

    hasBrokenLight(): boolean {
        for (const l of this.lights) {
            if (l.isBroken()) {
                return true;
            }
        }
        return false;
    }

    getBrokenLights(): Light[] {
        const ret: Light[] = [];
        this.lights.forEach(l => {
            if (l.isBroken()) {
                ret.push(l);
            }
        });

        return ret;
    }

    getRandomLight(): Light {
        return this.lights[Math.floor(Math.random() * this.lights.length)];
    }

    getName(): string {
        return 'Licht';
    }

    getIcon(): string {
        return 'light';
    }

    getDisplayMap(): Map<string, DisplayInfos> {
        const map = new Map<string, DisplayInfos>();

        this.lights.forEach(l => {
            map.set(l.position, new DisplayInfos([l.isWorking ? 'OK' : 'Defekt'], l.isWorking ? '#FFFFFF' : '#FFA500'));
        });

        return map;
    }

    getBadgeCount(): number {
        return this.getBrokenLights().length;
    }

    toggleShowInformationDetails(): void {
        this._showInformationDetails = !this._showInformationDetails;
    }

    showInformationDetails(): boolean {
        return this._showInformationDetails;
    }
}

export class Light {
    constructor(
        public position: string,
        public isWorking = true
    ) { }

    isBroken(): boolean {
        return !this.isWorking;
    }

    brake(): void {
        this.isWorking = false;
    }

    repair(): void {
        this.isWorking = true;
    }
}
