import { Configurable, DisplayInfos, InformationDashboardDisplayable, InputType, SettingsConfigurable } from './interfaces';

export class Pressure implements Configurable {
    constructor(
        public value: number,
        public unit: string = 'bar'
    ) { }

    getInputType(): InputType {
        return InputType.Text;
    }

    set(value: string): void {
        value = value.replace('bar', '');
        value = value.replace(' ', '');

        this.value = +value;
    }

    display(): string[] {
        return [this.value + ' ' + this.unit];
    }

    isBelow(o: Pressure): boolean {
        return this.value < o.value;
    }
}

export class TirePressure implements InformationDashboardDisplayable, SettingsConfigurable {
    public static warningPressure = new Pressure(1.5);
    public static targetgPressure = new Pressure(2.5);
    private _showSettingsDetails = true;
    private _showInformationDetails = true;

    readonly tires: Tire[] = [
        new Tire('Vorne links', TirePressure.targetgPressure),
        new Tire('Vorne rechts', TirePressure.targetgPressure),
        new Tire('Hinten links', TirePressure.targetgPressure),
        new Tire('Hinten rechts', TirePressure.targetgPressure)
    ];

    hasLowTirePressure(): boolean {
        for (const t of this.tires) {
            if (t.pressure.isBelow(TirePressure.warningPressure)) {
                return true;
            }
        }
        return false;
    }

    getLowPressureTires(): Tire[] {
        const ret: Tire[] = [];
        this.tires.forEach(t => {
            if (t.pressure.isBelow(TirePressure.warningPressure)) {
                ret.push(t);
            }
        });

        return ret;
    }

    getRandomTire(): Tire {
        return this.tires[Math.floor(Math.random() * this.tires.length)];
    }

    getName(): string {
        return 'Reifendruck';
    }

    getIcon(): string {
        return 'tire';
    }

    getDisplayMap(): Map<string, DisplayInfos> {
        const map = new Map<string, DisplayInfos>();

        this.tires.forEach(t => {
            map.set(t.position, new DisplayInfos(t.pressure.isBelow(TirePressure.warningPressure) ? [t.pressure.display()[0] + ' (niedrig)'] : t.pressure.display(), t.pressure.isBelow(TirePressure.warningPressure) ? '#FFA500' : '#FFFFFF'));
        });

        return map;
    }

    toggleShowInformationDetails(): void {
        this._showInformationDetails = !this._showInformationDetails;
    }

    showInformationDetails(): boolean {
        return this._showInformationDetails;
    }

    getBadgeCount(): number {
        return this.getLowPressureTires().length;
    }

    getSettingsMap(): Map<string, Configurable> {
        const map = new Map<string, Configurable>();

        map.set('Reifendruckwarnung unter', TirePressure.warningPressure);

        return map;
    }

    toggleShowSettingsDetails(): void {
        this._showSettingsDetails = !this._showSettingsDetails;
    }

    showSettingsDetails(): boolean {
        return this._showSettingsDetails;
    }
}

export class Tire {
    constructor(
        public position: string,
        public pressure: Pressure,
        private _broken = false
    ) { }

    isBroken(): boolean {
        return this._broken;
    }

    brake(): void {
        this.pressure = new Pressure(TirePressure.warningPressure.value - 0.1);
        this._broken = true;
    }

    repair(): void {
        this.pressure = new Pressure(TirePressure.targetgPressure.value);
        this._broken = false;
    }
}
