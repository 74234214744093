import { Injectable } from '@angular/core';
import { GasStation } from '../models/gas-station';

@Injectable({
  providedIn: 'root'
})
export class GasStationService {

  static mockUrl = new URL('https://goo.gl/maps/CpJxzeUUa9TBF1KC6');
  private gasStations: GasStation[] = [];

  constructor() {
    this.gasStations = this.createMockGasStations();
    this.gasStations.sort((o1, o2) => {
      if (o1.price > o2.price) {
        return 1;
      }
      if (o1.price < o2.price) {
        return -1;
      }
      return 0;
    });
  }

  private createMockGasStations(): GasStation[] {
    const gasStations: GasStation[] = [];
    gasStations.push(new GasStation(1, 'JET', '01727593754', 'Bad Canstatt', '70372', 'Hauptstraße', '1', 410, GasStationService.mockUrl));
    gasStations.push(new GasStation(2, 'Aral', '01729474638', 'Feuerbach', '70191', 'Schulstraße', '23', 721, GasStationService.mockUrl));
    gasStations.push(new GasStation(3, 'Esso', '01725644204', 'Vaihingen ', '71665', 'Gartenstraße', '12b', 890, GasStationService.mockUrl));
    gasStations.push(new GasStation(4, 'Agip', '017194874637', 'Zuffenhausen ', '70435', 'Dorfstraße', '27-29', 1100, GasStationService.mockUrl));
    gasStations.push(new GasStation(5, 'T-Tankstelle', '017174563830', 'Zuffenhausen ', '70435', 'Bahnhofstraße', '102', 1480, GasStationService.mockUrl));
    gasStations.push(new GasStation(6, 'OMV', '017185473638', 'Feuerbach', '70191', 'Bergstraße', '41', 3972, GasStationService.mockUrl));
    gasStations.push(new GasStation(7, 'BFT-Tankstelle', '01769404875', 'Ludwigsburg', '71634', 'Birkenweg', '8', 4801, GasStationService.mockUrl));
    gasStations.push(new GasStation(8, 'Freie Tankstelle', '017674628273', 'Leonberg', '71067', 'Lindenstraße', '21', 9824, GasStationService.mockUrl));
    gasStations.push(new GasStation(9, 'Star Tankstelle', '017684725413', 'Backnang', '71522', 'Kirchstraße', '57', 12820, GasStationService.mockUrl));
    return gasStations;
  }

  public getMockGasStations(): GasStation[] {
    return this.gasStations;
  }
}
