export class Location {
    constructor(
        readonly _row: Row,
        readonly side: Side
    ) { }

    get row(): Row {
        return this._row;
    }

    hasRow(row: number): boolean {
        return row === this.row.position;
    }

    hasSide(side: Side): boolean {
        return side === this.side;
    }

    display(): string {
        let str = this._row.position === 0 ? 'vorne ' : 'hinten ';
        str += this.side === Side.Left ? 'links' : 'rechts';
        return str;
    }
}

export class Row {
    constructor(private _position: number) { }

    get position(): number {
        return this._position;
    }
}

export enum Side {
    Left = 'left', Right = 'right', Back = 'back'
}
