<div class="d-flex flex-column h-100">
  <app-titlebar name="Temperatur" back="/cars/{{carId}}"></app-titlebar>
  <div class="d-flex flex-column flex-grow-1 m-3">
    <!-- Soll Temperatur -->
    <div class="d-flex flex-column">
      <div class="headline">Soll-Temperatur</div>
      <div class="d-flex flex-row">
        <div class="d-flex justify-content-start switch">
          <app-toggle-button [statusInput]="climateControl.active" (statusOutput)="handleSwitchClimateActive($event)"
            class="align-self-center"></app-toggle-button>
        </div>
        <app-target-indoor-configuration carId="{{carId}}" (targetTemperatureChange)="handleNewTargetTemperature()">
        </app-target-indoor-configuration>
      </div>
      <div *ngIf="!indoorIsSameAsTarget() && climateControl.active" class="mt-3 text-center">
        {{createActiveFeedback()}}.</div>
    </div>
    <hr style="border-top: 1px solid var(--text); width: 100%;">
    <!-- Zeitplan 1 -->
    <div class="d-flex flex-column">
      <div class="headline">Zeitplan 1</div>
      <div class="d-flex flex-row">
        <div class="d-flex justify-content-start switch">
          <app-toggle-button [statusInput]="climateControl.getTimeConfiguration(1).autoConfig"
            (statusOutput)="handleSwitchPeriodic($event, 1)" class="align-self-center"></app-toggle-button>
        </div>
        <app-schedule [id]="1" style="width: 100%;"></app-schedule>
      </div>
      <div *ngIf="this.climateControl.getTimeConfiguration(1).autoConfig" class="mt-3 text-center">
        {{createTimeplanFeedback(1)}}</div>
    </div>
    <hr style="border-top: 1px solid var(--text); width: 100%;">
    <!-- Zeitplan 2 -->
    <div class="d-flex flex-column">
      <div class="headline">Zeitplan 2</div>
      <div class="d-flex flex-row">
        <div class="d-flex justify-content-start switch">
          <app-toggle-button [statusInput]="climateControl.getTimeConfiguration(2).autoConfig"
            (statusOutput)="handleSwitchPeriodic($event, 2)" class="align-self-center"></app-toggle-button>
        </div>
        <app-schedule [id]="2" style="width: 100%;"></app-schedule>
      </div>
      <div  style="white-space: pre-line;" *ngIf="this.climateControl.getTimeConfiguration(2).autoConfig" class="mt-3 text-center">
        {{createTimeplanFeedback(2)}}</div>
    </div>
    <!-- Toggles -->
  </div>
</div>
