<div class="d-flex flex-row justify-content-center main">
  <!-- Soll Temperatur -->
  <div class="d-flex flex-column mr-3">
    <button class="btn" (click)="handleClickPlus()">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-up-fill" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
      </svg>
    </button>
    <span style="font-size: 28pt; font-weight:bold; line-height: 40px; height: 40px;">{{temperature}}</span>
    <button class="btn" (click)="handleClickMinus()">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
      </svg>
    </button>
  </div>
  <!-- Ist Temperatur -->
  <div class="d-flex flex-column justify-content-around ml-3">
    <div class="d-flex flex-column align-items-center">
      <span>innen</span>
      <span style="font-weight: bold;">{{currentIndoorTemp}}</span>
    </div>
    <div class="d-flex flex-column align-items-center">
      <span>außen</span>
      <span style="font-weight: bold;">{{currentOutdoorTemp}}</span>
    </div>
  </div>
</div>
