import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Car, CarWindow } from 'src/app/models/car';
import { CarService } from 'src/app/services/car.service';

@Component({
  templateUrl: './car-dashboard.component.html',
  styleUrls: ['./car-dashboard.component.css']
})
export class CarDashboardComponent implements OnInit {
  static readonly allComponents: CarDashboardComponent[] = [];

  carId: number;
  selectedCar: Car;
  climateOn: boolean;
  overviewBadge: string;
  gasBadge: string;
  diagnosisBadge: string;
  windowAlertText = '';

  static updateCarDashboards(): void {
    this.allComponents.forEach(c => c.ngOnInit());
  }

  constructor(
    private route: ActivatedRoute,
    private service: CarService
  ) {
    CarDashboardComponent.allComponents.push(this);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carid');
      this.selectedCar = this.service.getCarById(this.carId);
      this.selectedCar.updateMessages();

      if (this.selectedCar.isShutdown()) {
        this.overviewBadge = '';
      } else {
        this.overviewBadge = '!';
      }
      this.gasBadge = this.selectedCar.fuelSystem.isLowFuelLevel ? '!' : '';

      this.diagnosisBadge = this.selectedCar.diagnose.diagnoseItems.length > 0 ? '!' : '';
    });
    this.climateOn = this.selectedCar.climateControl.active;
  }

  get navigateToWindows(): string {
    return 'overview';
  }

  shutdown(): void {
    this.selectedCar.shutdown();
    
    if (!this.selectedCar.isShutdown()) {
      this.windowAlert(this.selectedCar.getBrokenWindows());
    } else {
      this.overviewBadge = '';
    }
  }

  windowAlert(windows: CarWindow[]): void {
    let str = '';
    windows.forEach(w => {
      str +=  w.display() + ' defekt\n';
    });

    this.windowAlertText = str;
  }

  closeAlert(): void {
    this.windowAlertText = '';
  }

  unlockAllDoors(): void {
    this.selectedCar.unlockAllDoors();
    this.overviewBadge = '!';
  }

  toggleClimate(): void {
    this.climateOn = !this.climateOn;
    this.selectedCar.climateControl.active = this.climateOn;
  }

  get cars(): Car[] {
    return this.service.cars;
  }

}
