<div class="d-flex flex-column h-100">
    <app-titlebar class="mb-3" name="Tanken" back="/cars/{{carId}}"></app-titlebar>

    <div class="d-flex flex-column flex-grow-1 m-3">
        <div class="d-flex flex-column">
            <div class="row mb-3">
                <div class="col-6">
                    <div class="headline">Tankfüllstand</div>
                </div>
                <div class="col-6">
                    <img src="assets/zapfsaeule.svg" height="40px" />
                </div>
            </div>

            <div class="row" *ngFor="let entry of fuelSystem.getDisplayMap() | keyvalue">
                <div class="col-6 text">{{entry.key}}</div>
                <div class="col-6" style="color:{{entry.value.displayColor}}">{{entry.value.displayString}}</div>
            </div>
            <div class="row">
                <div class="col-6 text">Benzin</div>
                <div class="col-6">Super E10</div>
            </div>
        </div>

        <hr style="border-top: 1px solid var(--text); width: 100%;">

        <div class="d-flex flex-column">
            <div class="row">
                <div class="col-12">
                    <div class="headline mb-3">Tankstelle finden</div>
                </div>
            </div>
            <div class="row">
                <div class="align-self-center label">
                    <span>Suche</span>
                </div>
                <app-search-box [preSelectIndex]="0" [displayNames]="gasStationDisplayNames"
                    (selectedIndex)="handleSearchIndex($event)"></app-search-box>
                <div *ngIf="selectedGasStation !== undefined" class="mt-3">
                    <div class="d-flex flex-row row">
                        <span class="label">Name:</span>
                        <span>{{selectedGasStation.name}}</span>
                    </div>
                    <div class="d-flex flex-row row">
                        <span class="label">Straße:</span>
                        <span>{{selectedGasStation.street}} {{selectedGasStation.streetAppendix}}</span>
                    </div>
                    <div class="d-flex flex-row row">
                        <span class="label">Stadt:</span>
                        <span>{{selectedGasStation.plz}} {{selectedGasStation.city}}</span>
                    </div>
                    <div class="d-flex flex-row row">
                        <span class="label">Entfernung:</span>
                        <span>{{selectedGasStation.getDistanceDisplay()}}</span>
                    </div>
                    <div class="d-flex flex-row row">
                        <span class="label">Preis pro Liter:</span>
                        <span style="color:{{selectedGasStation.color}}">{{selectedGasStation.price}}</span>
                    </div>
                    <div *ngIf="selectedCar.fuelSystem._levelPercent.value < 100" class="d-flex flex-row row">
                        <span class="label">Preis voll tanken:</span>
                        <span>{{calculatePrice()}}</span>
                    </div>
                    <div class="d-flex flex-row row">
                        <span class="label">Navigation:</span>
                        <span><a href="{{selectedGasStation.url}}" target="_blank">Google Maps</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>